
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import Filters from "./crafted/widgets/Filters.vue";
import membersApi from "@/core/services/MembersApi";
import { ElMessage, ElMessageBox } from "element-plus";

export default defineComponent({
    name: "NewJoinNow",
    components: { Filters },
    data() {
        return {
            tagsKey: 0,
            newJoinNow: [] as any,
            newJoinNowTh: [
                {
                    label: "Name",
                    class: "min-w-130px",
                    colName: "name",
                },
                {
                    label: "Contact",
                    class: "min-w-130px",
                    colName: "contact",
                },
                {
                    label: "Birthdate",
                    class: "min-w-120px",
                    colName: "birthdate",
                },
                {
                    label: "Bailliage",
                    class: "min-w-100px",
                    colName: "bailliage",
                },
                {
                    label: "Sponsor",
                    class: "min-w-150px",
                    colName: "sponsor",
                },
                {
                    label: "Added on",
                    class: "min-w-175px",
                    colName: "added_on",
                },
            ],
            selectedMembers: [] as any,
            membersSearch: "",
            pages: [] as any,
            loading: true,
            actualFilter: "",
            actualCol: "",
            currentPage: this.savedPage ? this.savedPage : (1 as any),
            newJoinNowCount: 0,
            totalPages: 0,
            perm_pos: "",
        };
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const resultsPerPage = 10;

        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        onMounted(() => {
            setCurrentPageBreadcrumbs("Join Now", []);
        });

        return { router, route, debounce: createDebounce(), resultsPerPage };
    },
    mounted() {
        this.getJoinNow();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
    },
    methods: {
        formatDate(date: string, locale?: string) {
            if (date !== null && date !== undefined) {
                var message: string;

                if (locale) message = new Date(date.split(" ")[0]).toLocaleString(locale).split(" ")[0];
                else message = new Date(date.split(" ")[0]).toLocaleString().split(" ")[0];

                return message;
            } else {
                return "-";
            }
        },
        acceptMember(member: any) {
            const stringMember = JSON.stringify(member);
            localStorage.setItem("prospect", stringMember);
            this.router.push({ name: "member-add" });
        },
        declineMember(member: any) {
            ElMessageBox.confirm("Are you sure you want to decline this member?", "Confirmation", {
                confirmButtonText: "Yes",
                cancelButtonText: "Cancel",
                customClass: "custom-modal",
                cancelButtonClass: "cancel-modal",
                dangerouslyUseHTMLString: true,
            })
                .then(() => {
                    membersApi.declineJoinNow(member.id).then(() => {
                        ElMessage({
                            message: "Archived request",
                            type: "success",
                        });
                        this.getJoinNow();
                    });
                })
                .catch(() => {
                    return; // Cancel
                });
        },
        handleSizeChange() {
            this.getJoinNow();
        },
        applyFilters() {
            var closeFilters = document.getElementById("filter") as any;
            closeFilters.click();
            this.getJoinNow();
        },
        getJoinNow() {
            var payload;
            this.loading = true;
            this.selectedMembers = [];
            this.newJoinNow = [];

            if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    status: "active",
                    column: this.actualCol,
                    order: this.actualFilter,
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    status: "active",
                };
            }

            if (this.membersSearch) {
                payload = { ...payload, query: this.membersSearch };
            }

            membersApi.getJoinNow(payload).then((res: any) => {
                res.data.prospects_list.map((member: any) => {
                    this.newJoinNow.push(member);
                });
                this.newJoinNowCount = res.data.prospects_count;
                this.totalPages = res.data.page_count;
                this.loading = false;
            });
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons" + id);

            // Hide all arrows
            Array.from(document.getElementsByClassName("chevrons-container") as any).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.getJoinNow();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up") chevrons = Array.from(document.getElementsByClassName("fa-chevron-up") as any);
            else if (direction === "down") chevrons = Array.from(document.getElementsByClassName("fa-chevron-down") as any);

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },

        handlePagination(pageNumber: any) {
            this.selectedMembers = [];
            this.currentPage = pageNumber;
            this.getJoinNow();
        },
        queryChanged(query: string) {
            this.membersSearch = query;
            this.getJoinNow();
        },
    },
});
